import { Button, DeterminateLoader, HRule, Modal } from '@imposium-hub/components';
import { IStory } from '../constants/snippets';
import { api } from '../constants/app';
import { useEffect, useState } from 'react';
import { updateModal as copy } from '../constants/copy';
import { ICON_WARNING } from '../constants/icons';

interface IUpdateVersionModalProps {
    story: IStory;
}

export const UpdateVersionModal = (props: IUpdateVersionModalProps) => {
    const [suppressUpdate, setSuppressUpdate] = useState(false);
    const { versionUpdateJobId, id } = props.story;
    const [updateError, setUpdateError] = useState(null);
    const [updateJobId, setUpdateJobId] = useState(versionUpdateJobId);
    const [jobComplete, setJobComplete] = useState(false);
    const paragraphCopy = updateError
        ? copy.error
        : jobComplete
        ? copy.complete
        : updateJobId
        ? copy.updating
        : copy.prompt;
    const [updateProgress, setUpdateProgress] = useState(0);

    useEffect(() => {
        let jobPollingInterval;
        if (updateJobId) {
            const pollJobId = (jobId) => {
                api.getJob(updateJobId)
                    .then((res) => {
                        // If it's still processing, set the timeout to poll again
                        if (res.processing === false) {
                            // If the job errored out, show the error in the interface
                            if (res.status === 'failed') {
                                setUpdateError(res.error);
                            }
                            setUpdateJobId(null);
                            setJobComplete(true);
                            clearInterval(jobPollingInterval);
                        } else {
                            if (res.output) {
                                const progress = res.output.completed / res.output.total;
                                setUpdateProgress(progress * 100);
                            }
                        }
                    })
                    .catch((e) => {
                        console.error('Error polling job ID');
                        console.error(e);
                        setUpdateError(e.message);
                    });
            };

            jobPollingInterval = setInterval(() => pollJobId(updateJobId), 1000);
            pollJobId(updateJobId);
        }

        return () => {
            clearInterval(jobPollingInterval);
        };
    }, [updateJobId]);

    const onUpdate = () => {
        api.updateProjectVersion(id)
            .then((res) => {
                setUpdateJobId(res.job_id);
            })
            .catch((e) => {
                console.error('Error starting version update');
                console.error(e);
                setUpdateError(e.message);
            });
    };

    const btnClose = (
        <Button
            key='btnClose'
            onClick={() => setSuppressUpdate(true)}
            size='large'
            color='default'>
            {copy.btnClose}
        </Button>
    );

    const initialButtons = [
        <Button
            key='btnUpdate'
            onClick={onUpdate}
            size='large'
            style='bold'
            color='primary'>
            {copy.btnUpdate}
        </Button>,
        btnClose
    ];

    const buttons = jobComplete ? btnClose : !updateJobId ? initialButtons : null;
    const error = updateError ? (
        <div className='update-error'>
            {ICON_WARNING} {updateError}
        </div>
    ) : null;

    const closeCallback = !updateJobId ? () => setSuppressUpdate(true) : null;
    return (
        <Modal
            onRequestClose={closeCallback}
            wrapperStyle={{
                top: '0px',
                left: '0px',
                width: '100%',
                height: `100%`
            }}
            style={{
                width: '450px',
                height: '170px',
                top: 'calc((100% - 170px) / 2)',
                left: 'calc((100% - 450px) / 2)'
            }}
            isOpen={!suppressUpdate}>
            <div className='version-update-modal'>
                <div className='version-update-header'>{copy.header}</div>
                <p>{paragraphCopy}</p>
                <HRule />
                <div className='confirm-buttons'>{error ? error : buttons}</div>
                {updateJobId && !error && (
                    <div className='progress-wrapper'>
                        <DeterminateLoader progress={updateProgress} />
                    </div>
                )}
            </div>
        </Modal>
    );
};
